import { MODAL_MEMO_ADD, MODAL_ACCOUNT_EDIT, MODAL_SHARING_BY_INVITATION } from 'constants/modal';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Input from 'Input';
import Avatar from 'Avatar';
import Tooltip from 'Tooltip';
import { translate } from 'react-i18next';
import { getAllGroupDropdown } from 'main/vault/groups/reduxState';
import { showModal } from 'main/modal';
import sharedAccountIcon from 'icons/upwardarrow.png';
import sharedToMeIcon from 'icons/downwardarrow.png';
import hide from 'assets/icons/hide.png';
import show from 'assets/icons/show.png';
import Styles from './Styles.css';
import GStyles from '../../styles/index.css';
import Container from './container';
import { getIconColorNewmethod } from '../../utils/misc';
import HoverImg from '../../assets/images/hover.png';

const columns = [
  {
    sortable: false,
  },
  {
    name: 'dashboard',
    label: 'Dashboard',
    sortable: true,
    tooltip: 'sort by dashboard names',
  },
  {
    name: 'name',
    label: 'Accounts',
    sortable: true,
    tooltip: 'sort by account names',
  },
  {
    name: 'email',
    label: 'Email',
    sortable: true,
    tooltip: 'sort by emails',
  },
  {
    name: 'username',
    label: 'Username',
    sortable: true,
    tooltip: 'sort by usernames',
  },
  {
    name: 'password',
    label: 'Password',
    sortable: true,
    tooltip: 'sort by passwords',
  },
  {
    name: 'groupId',
    label: 'Group',
    sortable: true,
    tooltip: 'sort by groups',
  },
  {
    name: '',
    sortable: false,
  },
];

function compareValues(a, b, key, order, group = false, dashboards, dashboardUserName) {
  let varA = a[key];
  let varB = b[key];
  if (key === 'dashboard') {
    varA = dashboardUserName;
    varB = dashboardUserName;
    if ('dashboardSpaceId' in a && a.dashboardSpaceId != null && a.dashboardSpaceId in dashboards) {
      varA = dashboards[a.dashboardSpaceId].name;
    }
    if ('dashboardSpaceId' in b && b.dashboardSpaceId != null && b.dashboardSpaceId in dashboards) {
      varB = dashboards[b.dashboardSpaceId].name;
    }
  }
  let result;

  if (!varA && !varB) {
    return 0;
  }

  if (!varA) {
    result = 1;
  }
  else if (!varB) {
    result = -1;
  }
  else if (group === true && varA === 'unsorted') {
    result = 1;
  }
  else if (group === true && varB === 'unsorted') {
    result = -1;
  }
  else {
    result = varA.localeCompare(varB);
  }
  return (
    (order === 'up') ? (result * -1) : result
  );
}

class AppsPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedField: 'name',
      selectedOrder: 'down',
      currentField: null,
      currentOrder: 'down',
      tableHeader: [],
    };

    this.splitAccounts = this.splitAccounts.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.mapObject = this.mapObject.bind(this);
  }

  componentDidMount() {
    if (!this.props.showUsername) {
      const res = columns.filter(item => item.label != 'Username');
      this.setState({
        tableHeader: res,
      });
    }
    else {
      this.setState({
        tableHeader: columns,
      });
    }
  }


  isUnsorted(id) {
    const { unsortedId } = this.props;
    return unsortedId === id || !id;
  }

  showModal(item) {
    if (!item.accepted) {
      this.props.showModal(MODAL_SHARING_BY_INVITATION, item);
    }
    else {
      this.props.showModal(MODAL_ACCOUNT_EDIT, item);
    }
  }

  splitAccounts(apps, selectedField, selectedOrder) {
    const { unsortedId } = this.props;
    const appsWithGroups = [];
    const appsWithoutGroups = [];
    Object.values(apps)
      .forEach(app => (app.groupId !== unsortedId ? appsWithGroups : appsWithoutGroups).push(app));

    const firstCategory =
      this.mapObject(appsWithGroups, this.renderRow, selectedField, selectedOrder);
    const firstCategoryWithHeader = firstCategory.length > 0 ?
      [this.renderHeader(), ...firstCategory] :
      [];
    const secondCategory =
      this.mapObject(appsWithoutGroups, this.renderRow, selectedField, selectedOrder);
    const secondCategoryWithHeader = secondCategory.length > 0 ?
      [this.renderHeader(true), ...secondCategory] :
      [];
    return [firstCategoryWithHeader, secondCategoryWithHeader];
  }

  mapObject(object, callback, selectedField, selectedOrder) {
    return Object.values(object)
      .sort((firstApp, secondApp) => (
        compareValues(
          firstApp,
          secondApp,
          selectedField,
          selectedOrder,
          false,
          this.props.dashboardList,
          this.props.username,
        )
      ))
      .map((app, index, apps) => callback(index, app, apps));
  }

  renderHeader(withGroup = true) {
    return (
      <tr>
        <td
          colSpan="5" className='top'>
            <span>{withGroup ? 'We found and categorised these accounts for you:' :
              'We weren\'t 100% sure if these are accounts. Please check and select:'
            }
            </span>
        </td>
      </tr>
    );
  }


  renderRow(id, item, items) {
    const { t } = this.props;
    const isSelected = this.props.selectedApps.includes(item.id);
    let dashboardName = this.props.username;
    let sharedWithDashboard;
    if ('dashboardSpaceId' in item && item.dashboardSpaceId != null && item.dashboardSpaceId in this.props.dashboardList) {
      dashboardName = this.props.dashboardList[item.dashboardSpaceId].name;
      sharedWithDashboard = 'shared' in this.props.dashboardList[item.dashboardSpaceId] ? this.props.dashboardList[item.dashboardSpaceId].shared : false;
    }
    return (
      <Fragment key={item.id}>
        <tr>
        {(this.props.isRemoveAccount || this.props.isMoveAccount) &&
          <td style={{ width: '0px' }}>
          </td>
        }
        {/* <Tooltip content='edit account info'>
          <td key={'hidden'}>
            <img
              // onClick={showEditModal}
              src={HoverImg}
              height={31}
              width={31}
              alt=''
              style={{ top: '20px' }}
              className={`${Styles.shared} ${Styles.HoverEffectIcon} ${Styles.addIcon}`}
            />
          </td>
        </Tooltip> */}
        {(this.props.isRemoveAccount) &&
          <td style={{ width: '205px' }}>
            <div style={{ float: 'left', width: '62px' }}>
              <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                <rect x='0' y='0' fill={getIconColorNewmethod(dashboardName)} height='100%' width='100%'></rect>
                <text
                  dominantBaseline='middle'
                  fill='white'
                  fontSize='10'
                  textAnchor='middle'
                  x='48%'
                  y='54%'
                >{ dashboardName.slice(0, 2).toUpperCase() }</text>
              </svg>
            {sharedWithDashboard ?
              <img
                src={sharedAccountIcon}
                style={{
                  position: 'relative', top: '-15px', left: '-14px',
                }}
                alt=''
                className={Styles.shared}
              /> : null
            }
            </div>
            <div style={{
              float: 'left', height: '37px', lineHeight: '38px', marginLeft: '-15px',
            }}>
              <div className={classNames({ [Styles.active]: isSelected })}
                    style={{ color: '#868692', verticalAlign: '-webkit-baseline-middle' }}>{dashboardName.length > 18 ? dashboardName.substring(0, 15).concat('...') : dashboardName}</div>
            </div>
          </td>
        }
        {!this.props.isRemoveAccount && !this.props.isMoveAccount &&
          <td></td>
        }
          <td>
            <label
              className={Styles.itemContent}
              htmlFor={item.accountId}>
              <div className={Styles.itemHeader} onClick={() => this.props.onSelectApp(item.id)}>
                <Tooltip content={item.domain}>
                  <div style={{ position: 'relative' }}>
                    {(this.props.isRemoveAccount || this.props.isMoveAccount) &&
                      <span style={{
                        width: '0px',
                        position: 'absolute',
                        top: '-25px',
                        left: '17px',
                        zIndex: '999999',
                        width: '30px',
                        opacity: '1',
                      }}>
                      { ((item.usersSharedWith && item.usersSharedWith.length > 0) ||
                         ('sharedByUserName' in item && item.sharedByUserName != false)) &&
                      item.shared && !sharedWithDashboard &&
                          <img
                            style={{ position: 'relative', top: '20px', left: '13px' }}
                            src={item.externalAccount ? sharedToMeIcon : sharedAccountIcon}
                            alt=''
                            className={Styles.shared}
                          />
                        }
                        {!item.accepted &&
                          <img
                            style={{ position: 'relative', top: '20px', left: '13px' }}
                            src={sharedToMeIcon}
                            alt=''
                            className={Styles.shared}
                          />
                        }
                      </span>
                    }
                    <Avatar
                      classNameIcon={Styles.iconListWrapper}
                      domain={item.domain}
                      name={item.name}
                      picture={item.picture}
                    />
                  </div>
                </Tooltip>
                <span className={classNames({
                  [Styles._singleLine]: 1,
                  [Styles.labelList]: 1,
                  [Styles.active]: isSelected,
                })}>
                  {item.name}
                </span>
              </div>
            </label>
          </td>
          <td>
            {!this.props.isEmailEditable ?
              <div className={classNames({
                [Styles.fixedEmail]: 1,
                [Styles.active]: isSelected,
              })}>
                { item.email }
              </div> :
              <Input
                id={item.id}
                modal={true}
                name='email'
                placeholder="Fill in now or later..."
                onChange={event => this.props.onChange(event, item.id)}
                t={t}
                value={item.email}
              />
            }
          </td>
          {this.props.showUsername &&
            <td>
              {!this.props.isEmailEditable ? (
                <div className={classNames({
                  [Styles.fixedEmail]: 1,
                  [Styles.active]: isSelected,
                })}>
                  { item.username || item.phone }
                </div>
              ) : (
                <Input
                  id={item.id}
                  modal={true}
                  name='username'
                  placeholder="Fill in now or later..."
                  t={t}
                  type='text'
                  onChange={event => this.props.onChange(event, item.id)}
                  value={item.username || item.phone}
                />
              )}
            </td>
          }
          <td>
            {!this.props.isPasswordEditable ?
              <Input
                id={item.id}
                modal={true}
                name='password'
                type='password'
                t={t}
                fromMoveAccountScreen={this.props.isMoveAccount}
                typeForceText={this.props.apps[item.id].showPassword}
                onClickPasswordToggle={() => this.props.onClickPasswordToggle(item.id)}
                background='grey'
                bgColorOnHover={false}
                propClassNames={{
                  [Styles.disabledInput2]: 1,
                  [Styles.active]: isSelected,
                }}
                value={item.password}
              /> :
              <Input
                id={item.id}
                modal={true}
                name='password'
                placeholder="Fill in now or later..."
                type='password'
                fromMoveAccountScreen={this.props.isMoveAccount}
                t={t}
                typeForceText={this.props.passwordList[item.id]}
                onClickPasswordToggle={() => this.props.onClickPasswordToggle(item.id)}
                onChange={event => this.props.onChange(event, item.id)}
                value={item.password}
                className={Styles.tableInput}
              />
            }
          </td>
          <td>
            {!this.props.isGroupEditable ?
              <input
                className={classNames({
                  [Styles.disabledInput1]: 1,
                  [Styles.active]: isSelected,
                })}
                type='text'
                value={item.groupId}
                disabled
              /> :
              <Input
                id={item.id}
                modal={true}
                autoComplete='off'
                reducedHeight
                dropdown={this.props.getGroupDropdown(item.groupId)}
                name="groupId"
                onChange={event => this.props.onChange(event, item.id)}
                placeholder="Select group.."
                t={t}
                top={(id >= items.length - 1 && items.length > 1) ||
                  (items.length === 1 && id === items.length - 1 && items.length > 1)}
                type='text'
                value={this.isUnsorted(item.groupId) ? '' : item.groupId}
              />
            }
          </td>
          <td>
            <div className={classNames({
              [Styles.inputRadioBorder]: 1,
              [Styles.inputRadioBorderActive]: isSelected,
              [Styles.removeAccount]: isSelected && (
                this.props.isRemoveAccount
              ),
            })}
              onClick={() => this.props.onSelectApp(item.id)}
            />
          </td>
        </tr>
        {item.meta && item.meta.mergeWith &&
          <tr>
            <td colSpan="1"></td>
            <td
              className={Styles.hintText}
              colSpan="5">
                <span>To be merged with existing account</span>
            </td>
          </tr>
        }
      </Fragment>
    );
  }

  handleSortChange(name) {
    let { selectedOrder } = this.state;
    if (selectedOrder === 'up') {
      selectedOrder = 'down';
    }
    else if (selectedOrder === 'down') {
      selectedOrder = 'up';
    }

    this.setState({
      selectedOrder,
      selectedField: name,
    });
  }

  handleMouseEnter(name) {
    this.setState({
      currentField: name,
    });
  }

  handleMouseLeave() {
    this.setState({
      currentField: null,
    });
  }

  render() {
    const { apps } = this.props;
    return (
      <Container
        apps={apps}
        split={this.props.split}
        top={this.props.top}
        showUsername={this.props.showUsername}
        splitAccounts={this.splitAccounts}
        mapObject={this.mapObject}
        renderRow={this.renderRow}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onSortChange={this.handleSortChange}
        currentField={this.state.currentField}
        selectedField={this.state.selectedField}
        selectedOrder={this.state.selectedOrder}
        currentOrder={this.state.currentOrder}
        columns={this.state.tableHeader}
        isRemoveAccount={this.props.isRemoveAccount}
        isMoveAccount={this.props.isMoveAccount}
      />
    );
  }
}

const mapStateToProps = state => ({
  dashboardList: state.ui.dashboards,
  username: state.user.profile.name,
});

const mapDispatchToProps = dispatch => ({
  showModal: (name, data) => dispatch(showModal(name, data)),
  getGroupDropdown: name => dispatch(getAllGroupDropdown(name)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppsPreview));

AppsPreview.defaultProps = {
  isEmailEditable: false,
  isPasswordEditable: true,
  isGroupEditable: true,
  isEmailNotEditable: false,
  isRemoveAccount: false,
  isMoveAccount: false,
  showUsername: false,
  split: false,
  passwordList: [],
};
