import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Button } from '@nettoken/components';
import withOverlayAction from 'Overlay/withAction';
import Video from 'Video';
import classNames from 'classnames';
import fadeAnimation from './style.css';

class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: 'block',
      modal: 'none',
    };
  }

  changeColor = () => {
    this.setState({ display: 'none' });
  }

  render() {
    return (
     <div style={ { display: this.state.display }} className={fadeAnimation.modal}>
     <div style={ { display: this.state.display }} className={fadeAnimation.modal_content}>
    <div
      className={classNames(fadeAnimation.formEmailScanPreviewBackup, fadeAnimation._boxPadding)}>
          <h1
      className={fadeAnimation.title}>
       Welcome to Nettoken
    </h1>
      <>
        <div style={{ marginTop: '40px' }} className={fadeAnimation.video_wrapper}>
          <Video
            videoUrl='https://fast.wistia.net/embed/iframe/jpv2wlsm8d?videoFoam=true'
          />
        </div>
        <Button
          onClick={this.changeColor}
          className={fadeAnimation.confirmButton}
          value={'LET\'S GET STARTED'}
        />
      </>

    </div>

    </div>
   </div>
    );
  }
}


const mapStateToProps = state => ({
  isGridView: state.ui.gridView,
  data: state.ui.modalData || {},
  appsTotal: Object.keys(state.credentials.data).length,
});

export default translate()(connect(
  mapStateToProps,
)(withOverlayAction(PopUp)));
