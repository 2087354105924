import React from 'react';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { LABEL_UNSORTED_GROUP } from '@nettoken/models';
import Avatar from 'components/Avatar';
import Tooltip from 'components/Tooltip';
import hide from 'assets/icons/hide.png';
import show from 'assets/icons/show.png';
import Styles from 'Modal/style.css';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import HidePasswordIcon from '../../../ActionIcons/HidePasswordIcon';
import ShowPasswordIcon from '../../../ActionIcons/ShowPasswordIcon';

const ModalPasswordCheckupContainer = ({
  credentialGroups,
  duplicatePasswordCount,
  hasDuplicatePasswords,
  goBack,
  groups,
  onCancel,
  onSubmit,
  selectedApp,
  step,
  t,
  unsortedId,
  goToEditAccount,
}) => (
  <>

    <h1
      aria-label={t('modal.accountAdd.title')}
      className={classNames({
        [Styles.title1]: true,
        [Styles.displayFlex]: step === 'summary',
      })}>
      {step == 'preview' ? '' : <LeftArrowIcon onClickBackHandler={goBack} />}
      {step === 'preview' ? 'Password Checkup' : 'Take Action'}
      <CancelIcon onCancel={onCancel} />
    </h1>
    <form
      action="/"
      className={classNames({
        [Styles.formEmailScanPreviewBackup]: step === 'preview',
        [Styles.whiteBackground]: step === 'summary',
        [Styles.minHeightBackground]: true,
      })}
      method="post"
      onSubmit={onSubmit}>
        {step === 'preview' ?
          <>
            <div className={`${Styles.importWrapper} ${Styles.paddingZero} ${Styles.fixedForm2}`} style={{ backgroundColor: '#DCDBDE' }}>
              <div className={classNames(Styles.row, Styles.header)}>
                <div className={Styles.cell}>Name</div>
                <div className={Styles.cell}>Email</div>
                <div className={Styles.cell}>Password</div>
                <div className={Styles.cell}>Group</div>
                <div className={Styles.cell}></div>
              </div>
              {hasDuplicatePasswords ? Object.values(groups).map(group => (
                <>
                  <div className={`${Styles.row} ${Styles.subHeader}`} style={{ backgroundColor: '#DCDBDE' }}>
                    The same password is used on these {group.length} accounts
                  </div>
                  <div className={Styles.rowWrapper}>
                    {group.map(account => (
                      <Row
                        account={account}
                        groupName={credentialGroups[account.groupId].label}
                        onTakeAction={onSubmit}
                        unsortedId={unsortedId}
                      />
                    ))}
                  </div>
                </>
              )) :
              <div className={Styles.row} style={{ paddingTop: '200px', backgroundColor: '#DCDBDE' }}>Looking good! We have found no reused passwords.</div>
              }
            </div>
            <div>
              {/* <Button
                className={Styles.button}
                onClick={onCancel}
                value={t('global.cancel')} /> */}
            </div>
          </> :
          <>
            <div className={`${Styles.form} ${Styles.fixedForm2}`}>
              <div className={classNames({
                [Styles.iconHeaderWrapper]: true,
                [Styles.noBg]: selectedApp.picture,
              })}
                style={{ marginBottom: 0 }}
              >
                <Avatar
                  domain={selectedApp.domain}
                  loginUrl={selectedApp.loginUrl}
                  name={selectedApp.name}
                  picture={selectedApp.picture}
                />
              </div>
              <p className={Styles._textCenter}>{selectedApp.name}</p>
              <p className={`${Styles.subTitle} ${Styles._textCenter}`}>
                {`You have resused this password ${duplicatePasswordCount} times`}
              </p>
              <p className={`${Styles.subTitle} ${Styles._textCenter}`}>
                Using the same password significantly
                increases the risk of your accounts being compromised.
              </p>
              <p className={`${Styles.subTitle} ${Styles._textCenter}`}>
                Take action and keep your Nettoken updated
              </p>
              <a
                className={`${Styles.highlighted} ${Styles.subTitle}`}
                href={selectedApp.loginUrl}
                target='_blank'
                rel='noopener noreferrer'>
                Go to the account and change password
                <i className={`${Styles.arrow} ${Styles.right}`} />
              </a>
              {/* <div className={Styles.actions}>
                <Button
                  className={Styles.button}
                  onClick={onCancel}
                  value={t('global.cancel')}
                />
                <Button
                  className={Styles.button}
                  type="submit"
                  value='Back'
                  onClick={goBack}
                />
              </div> */}
            </div>
            <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
              <Button
                className={`${Styles.button}`}
                onClick={goToEditAccount}
              value={'Edit Account'} />
            </div>
          </>
        }
    </form>
  </>
);

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };

    this.togglePassword = this.togglePassword.bind(this);
  }

  togglePassword() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }

  render() {
    const { account, groupName } = this.props;
    const { showPassword } = this.state;

    return (
      <div className={Styles.row}>
        <div className={Styles.cell}>
          <label
            className={`${Styles.itemContent} ${Styles.w100}`}
            htmlFor={account.accountId}>
            <div className={Styles.itemHeader}>
              <Tooltip content={account.domain}>
                <div>
                  <Avatar
                    classNameIcon={Styles.iconListWrapper}
                    domain={account.domain}
                    name={account.name}
                    picture={account.picture}
                  />
                </div>
              </Tooltip>
              <span className={classNames({
                [Styles._singleLine]: 1,
                [Styles.labelList]: 1,
                [Styles.active]: 1,
              })}
              style={{ fontSize: '16px' }}>
                {account.name}
              </span>
            </div>
          </label>
        </div>
        <div className={Styles.cell}>
          <span className={Styles._singleLine}>{account.email}</span>
        </div>
        <div className={Styles.cell}>
          <div className={Styles.inputWrapper}>
            <input
              className={classNames({
                [Styles.disabledInput]: 1,
                [Styles.active]: 1,
              })}
              type={showPassword ? 'text' : 'password'}
              value={account.password}
              disabled
            />
            {account.password.length > 0 &&
            <Tooltip content={showPassword ? 'hide password' : 'show password'}>
              <span
                className={Styles.showText}
                onClick={this.togglePassword}>
                  {
                    showPassword ? (
                      <HidePasswordIcon />
                    ) : (
                      <ShowPasswordIcon />
                    )
                  }
              </span>
            </Tooltip>}
          </div>
        </div>
        <div className={Styles.cell}>
          <div className={Styles.inputWrapper} style={{ paddingRight: '0px' }}>
            <input
              className={classNames({
                [Styles.disabledInput]: 1,
                [Styles.active]: 1,
              })}
              type='text'
              value={groupName !== LABEL_UNSORTED_GROUP ? groupName : ''}
              disabled
            />
          </div>
        </div>
        <div>
          <button
            className={Styles.actionButton}
            onClick={event => this.props.onTakeAction(event, account.id)}
            >
            Take action
            <i className={`${Styles.arrow} ${Styles.right}`}/>
          </button>
        </div>
      </div>
    );
  }
}

export default ModalPasswordCheckupContainer;
