import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import Video from 'Video';
import { EnhancedAddChrome } from 'pages/homepage/storeButtons';
import imageSteps from 'images/3-steps.png';
import Styles from 'Modal/style.css';

const ModalWelcomeVideoContainer = ({
  goToDashboard,
  onSubmit,
  step,
  videoUrl,
}) => (
  <Fragment>
    <h1
      className={Styles.title}>
       Welcome to Nettoken
    </h1>
    <div
      className={classNames(Styles.formEmailScanPreviewBackup, Styles._boxPadding)}>
      <>
        <div className={`${Styles.fixedForm4}`}>
          <div className={Styles.video_wrapper}>
            <Video
              videoUrl='https://fast.wistia.net/embed/iframe/jpv2wlsm8d?videoFoam=true'
            />
          </div>
        </div>
      </>
    </div>
    <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles.right}`} style={{ width: '780px', bottom: '8.5vh' }}>
      <Button
        className={Styles.confirmButton}
        onClick={onSubmit}
        value={'LET\'S GET STARTED'}
      />
    </div>
  </Fragment>
);

export default ModalWelcomeVideoContainer;
