/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH1 } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Counter from 'Counter';
import AppsPreview from 'AppsPreview';
import Styles from 'Modal/style.css';
import sharedAccountIcon from 'icons/upwardarrow.svg';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import ClearIcon from '../../../ActionIcons/ClearIcon';
import GStyles from '../../../../styles/index.css';
import { getIconColorNewmethod } from '../../../../utils/misc';
import dropdown from '../../../../assets/images/leftArrow_icon/active.svg';

class ModalAccountRemoveContainer extends React.Component {
  getRowLabel(app) {
    let { name } = app;
    if (app.displayLogin) name += ` (${app.displayLogin})`;
    return name;
  }

  header() {
    const { step, t, selectedDashboardName } = this.props;
    if (step === 'select') {
      return (
      <React.Fragment>
        {this.props.dashboards.length >= 1 && (
          <div style={{
            display: 'flex',
            background: '#dcdbde',
            placeContent: 'center space-evenly',
            justifyContent: 'center',
            padding: '10px',
          }}>
            <div>
              <div
                style={{
                  height: '48px',
                  minWidth: '200px',
                  display: 'grid',
                  background: 'white',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '22px',
                  position: 'relative',
                  gridTemplateColumns: '0.1fr 0.6fr 0.2fr',
                  columnGap: '13px',
                }}
                onClick={this.props.openDropdown}
              >
                  {this.props.selectedDashboardId &&
                    !this.props.dashboardList[this.props.selectedDashboardId]
                      .externalDashboard &&
                    'usersSharedWith' in this.props.dashboardList[this.props.selectedDashboardId] &&
                    this.props.dashboardList[this.props.selectedDashboardId]
                      .usersSharedWith &&
                    this.props.dashboardList[this.props.selectedDashboardId]
                      .usersSharedWith.length > 0 ?
                    (
                    <img
                      src={sharedAccountIcon}
                      alt=''
                      className={Styles.sharedIcon}
                      style={{ position: 'absolute', left: '26px', top: '-4px' }}
                    />
                    ) : null}
                <div style={{ float: 'left' }}>
                  <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                    <rect x='0' y='0' fill={getIconColorNewmethod(this.props.selectedDashboard)} height='100%' width='100%'></rect>
                    <text
                      dominantBaseline='middle'
                      fill='white'
                      fontSize='10'
                      textAnchor='middle'
                      x='48%'
                      y='54%'
                    >{this.props.selectedDashboard.substring(0, 2).toUpperCase()}</text>
                  </svg>
                </div>
                <div style={{ float: 'left', textAlign: 'left' }}>
                  <div style={{ fontWeight: 'bold' }}>{this.props.selectedDashboard.substring(0, 10)}</div>
                  <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${this.props.selectedDashboardCounter} accounts`}</div>
                </div>
                <img src={dropdown} style={{
                  transform: 'rotate(270deg)',
                }} />
              </div>
              <ul
                style={{
                  display: `${this.props.showDropdown ? 'block' : 'none'}`,
                  position: 'absolute',
                  overflow: 'auto',
                  height: '25em',
                  zIndex: '5',
                }}
                className={Styles.dashboardDropdownMoveAccounts}
              >
                {this.props.dashboards.map(dash => (
                  <li
                    onClick={
                      () => this.props.onChangeDashboard(
                        dash.value,
                        dash.name,
                        dash.counter,
                      )
                    }
                    style={{
                      borderLeft: '1px solid #dcdbde',
                      borderRight: '1px solid #dcdbde',
                      background: 'white',
                      height: '48px',
                      minWidth: '200px',
                      display: 'grid',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                      gridTemplateColumns: '0.1fr 0.6fr 0.2fr',
                      columnGap: '13px',
                    }}
                  >
                    {!dash.externalDashboard &&
                      'usersSharedWith' in dash &&
                      dash.usersSharedWith &&
                      dash.usersSharedWith.length > 0 ?
                      (
                        <img
                          src={sharedAccountIcon}
                          alt=''
                          className={Styles.sharedIcon} style={{ position: 'absolute', left: '26px', top: '-4px' }}
                        />
                      ) : null
                    }
                    <div style={{ float: 'left' }}>
                      <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                        <rect x='0' y='0' fill={getIconColorNewmethod(dash.name)} height='100%' width='100%'></rect>
                        <text
                          dominantBaseline='middle'
                          fill='white'
                          fontSize='10'
                          textAnchor='middle'
                          x='48%'
                          y='54%'
                        >{dash.name.substring(0, 2).toUpperCase()}</text>
                      </svg>
                    </div>
                    <div style={{ float: 'left', textAlign: 'left' }}>
                      <div style={{ fontWeight: 'bold' }}>{dash.name.substring(0, 10)}</div>
                      <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${dash.counter} accounts`}</div>
                    </div>
                    {/* <img src={dropdown} style={{ visibility: 'hidden' }} /> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className={`${Styles.searchWrapper} ${Styles.stickySearchHeader2} ${Styles.addAccountSearchBar}`}>
          <input
            aria-label={t('placeholder.search')}
            className={Styles.search}
            onChange={this.props.onChangeSearch}
            placeholder={`${t('placeholder.search')}...`}
            role="search"
            type="text"
            id={SEARCH1}
            value={this.props.query} />
                {this.props.query.length > 0 ?
                  <ClearIcon onClickClearSearch={this.props.onClickClearSearch} /> :
                  null
                }
        </div>
        <div className={`${Styles.actions2} ${Styles.stickyActionBar2}`}></div>
      </React.Fragment>
      );
    }

    if (step === 'confirm') {
      return (
        <div>
          <div style={{
            background: '#dcdbde',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0px',
            padding: '10px',
            gap: '10px',
          }}>
            {Object.values(selectedDashboardName).map(dash => (
              <div style={
                {
                  background: 'white',
                  height: '48px',
                  minWidth: '200px',
                  display: 'grid',
                  justifyContent: 'space-around',
                  alignContent: 'center',
                  alignItems: 'center',
                  borderRadius: '22px',
                  position: 'relative',
                  gridTemplateColumns: '0.1fr 0.6fr 0.2fr',
                  columnGap: '13px',
                }
              }>
                {!dash.externalDashboard &&
                'usersSharedWith' in dash &&
                dash.usersSharedWith &&
                dash.usersSharedWith.length > 0 ?
                  (
                    <img
                      src={sharedAccountIcon}
                      alt=''
                      className={Styles.sharedIcon} style={{ position: 'absolute', left: '26px', top: '-4px' }}
                    />
                  ) : null
                }
                <div style={{ float: 'left' }}>
                  <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                    <rect x='0' y='0' fill={getIconColorNewmethod(dash.name.toString())} height='100%' width='100%'></rect>
                    <text
                      dominantBaseline='middle'
                      fill='white'
                      fontSize='10'
                      textAnchor='middle'
                      x='48%'
                      y='54%'
                    >{dash.name.toString().substring(0, 2).toUpperCase()}</text>
                  </svg>
                </div>
                <div style={{ float: 'left', textAlign: 'left' }}>
                  <div style={{ fontWeight: 'bold' }}>{dash.name.toString().substring(0, 10)}
                  </div>
                  <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${dash.counter} accounts`}</div>
                </div>
                {/* <img src={dropdown} style={{ visibility: 'hidden' }} /> */}
              </div>
            ))}
          </div>
          <div className={classNames({
            [Styles.search]: true,
            [Styles.alignCenter]: true,
            [Styles.searchWrapper]: true,
            [Styles.stickySearchHeader2]: true,
          })}>
            Check again, you are about to remove these accounts:
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { step, t } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountRemove.title')}
          className={`${Styles.title} ${Styles.stickyHeaderList}`}>
          <LeftArrowIcon onClickBackHandler={this.props.onClickBackHandler} />
          Remove accounts
          <CancelIcon onCancel={this.props.onCancel} />
        </h1>


        <form
          action="/"
          className={`${Styles.formEmailScanPreview} ${Styles.stickyMinHeightBackground}`}
          method="post"
          onSubmit={this.props.onSubmit}
          style={{
            paddingTop: '0px',
            maxWidth: '1050px',
          }}
          >
            <div className={`${Styles.stickyfixedForm2}`}>
              {this.header()}
              <div className={classNames({
                [Styles.addAccountList]: true,
                [Styles.addAccountListPreview]: step === 'confirm',
              })}>
                <AppsPreview
                  apps={this.props.apps}
                  isEmailEditable={false}
                  isPasswordEditable={false}
                  isGroupEditable={false}
                  // isRemoveAccount={true}
                  onClickPasswordToggle={this.props.onClickPasswordToggle}
                  onSelectApp={this.props.onClickApp}
                  selectedApps={this.props.selected}
                  showUsername={true}
                />
              </div>
            </div>

          <div className={classNames({
            [Styles.actions]: true,
            [Styles.accountCounter]: true,
            [Styles.actionsFixed]: true,
          })} style={{ maxWidth: '1050px', width: '92vw' }}>

            <span>
              <Counter
                noGreyBar={true}
                colour="#fff"
                count={this.props.selected.size}
                font="sm"
                label={'Accounts'}
                size={32}
              />
            </span>

            <div>
              <Button
                className={classNames({
                  [Styles.button]: true,
                  [Styles.danger]: true,
                })}
                onClick={e => e.target.blur()}
                disabled={!this.props.canSubmit}
                loaderSize={20}
                loading={this.props.isSubmitting}
                type="submit"
                value={t(this.props.step === 'select' ? 'Preview' : 'Remove')} />
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

ModalAccountRemoveContainer.defaultProps = {
  apps: [],
  canSubmit: false,
  isLoading: true,
  isSubmitting: false,
  onCancel: noop,
  onChangeSearch: noop,
  onClickApp: noop,
  onSubmit: noop,
  query: '',
  selected: 0,
  step: '',
  selectedDashboardName: [],
};

ModalAccountRemoveContainer.propTypes = {
  apps: PropTypes.array,
  canSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onClickApp: PropTypes.func,
  onClickClearSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  query: PropTypes.string,
  selected: PropTypes.number,
  step: PropTypes.string,
  selectedDashboardName: PropTypes.array,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalAccountRemoveContainer;
