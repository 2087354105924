/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_REMOVE_ACCOUNT } from 'constants/routes';
import { SEARCH1 as ID_SEARCH } from 'constants/ids';
import { MODAL_ADD_ACCOUNT_NAVLIST } from 'constants/modal';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import debounce from 'lodash.debounce';
import { eventTarget } from '@nettoken/helpers';
import { LABEL_UNSORTED_GROUP } from '@nettoken/models';
import { arrayItemSliceOrAppend } from '@nettoken/helpers';
import { hideModal, showModal } from 'main/modal';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { sharedInvitation } from 'main/sharedAccounts';
import { deleteCredentials } from 'main/vault/credentials';
import { readGroup } from 'main/vault/groups/reduxState';
import { history } from 'reducers/store';
import withAsyncState from 'AsyncState';
import withOverlayAction from 'Overlay/withAction';
import Debouncer from 'utils/debouncer';
import $ from 'jquery';
import Container from './container';
import { MODAL_OFFLINE_MSG } from '../../../../constants/modal';
import { RxEditAccountProccessing, RXToasterShow } from '../../../../main/modal/reduxActions';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';


const debouncedSearch = new Debouncer(params => params.search(), 200);
class ModalAccountRemoveComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apps: {},
      filteredApps: {},
      isLoading: false,
      isSubmitDisabled: true,
      isSubmitting: false,
      query: '',
      selected: new List(),
      selectedApps: new List(),
      step: 'select',
      dashboardDropdown: [],
      selectedDashboard: '',
      selectedDashboardId: null,
      searchQuery: '',
      selectedDashboardName: {},
      selectedDashboardCounter: 0,
      showDropdown: false,
      currentDashboard: '',
      dashboardName: '',
      cdid: '',
      secondDashboard: '',
      dashboardCounter: '',
    };

    this.debounce = debounce(() => this.search(this.state.query), 300);
    this.handleSearch = debounce(() => this.searchCallback(this.state.query), 200);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadAccounts = this.loadAccounts.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.loadComponentData = this.loadComponentData.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.onChangeDashboard = this.onChangeDashboard.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hidePopup);
    this.checkHashString();
    // this.loadAccounts();
    this.loadComponentData();
  }

  hidePopup() {
    if (!this.state.isSubmitting) {
      this.props.hide();
    }
  }

  checkHashString() {
    history.replace({ search: `?${MODAL_REMOVE_ACCOUNT}`, hash: '' });
  }

  handleChange(event) {
    const { value: query } = eventTarget(event);
    this.setState({ query }, this.handleSearch);
  }

  handleChangeSearch(event) {
    const query = '';
    this.setState({ query }, this.handleSearch);
  }

  clearSearch() {
    const input = $(`#${ID_SEARCH}`);
    if (input) input.focus();
    this.handleChangeSearch();
  }

  searchCallback(query) {
    if (!query) {
      this.setState({
        filteredApps: this.state.apps,
      });
      return;
    }

    const { apps } = this.state;
    query = query.toLowerCase();
    const filteredApps = Object.keys(apps)
      .filter(key => {
        if (
          apps[key].name.toLowerCase().includes(query) ||
          apps[key].email.toLowerCase().includes(query) ||
          apps[key].groupId.toLowerCase().includes(query)
        ) {
          return true;
        }
        return false;
      })
      .reduce((obj, key) => {
        obj[key] = apps[key];
        return obj;
      }, {});

    this.setState({
      filteredApps,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (!navigator.onLine) {
      this.setSubmitting(true);
      this.props.setEditAccountProccessing(true);
      setTimeout(() => {
        this.setSubmitting(false);
        this.props.setEditAccountProccessing(false);
      }, 2000);
      this.props.showToaster({
        status: 'open',
        type: 'error',
        value: 'Oops, could not save! Please try again',
      });
      return;
    }

    // Modal UI can be bypassed by pressing Enter, do
    // a manual check here. If nothing was selected,
    // we do not allow user to submit the modal.
    if (!this.state.selected.size) return;

    if (this.state.step === 'select') {
      const { apps, selected } = this.state;
      let selectedDashboardName = {};
      const filteredApps = Object.keys(apps)
        .filter(key => selected.includes(apps[key].id))
        .reduce((obj, key) => {
          if (
            !('dashboardSpaceId' in apps[key]) ||
            apps[key].dashboardSpaceId === '' ||
            apps[key].dashboardSpaceId === null
          ) {
            if (!('home' in selectedDashboardName)) {
              selectedDashboardName = {
                ...selectedDashboardName,
                home: {
                  name: this.props.userName,
                  counter: this.props.totalCounter,
                },
              };
            }
          }
          if (
            'dashboardSpaceId' in apps[key] &&
            apps[key].dashboardSpaceId !== '' &&
            apps[key].dashboardSpaceId !== null &&
            !(apps[key].dashboardSpaceId in selectedDashboardName)
          ) {
            const counter = Object.values(this.props.credentials).filter(cred => {
              if (
                'dashboardSpaceId' in cred &&
                cred.dashboardSpaceId === apps[key].dashboardSpaceId
              ) {
                return true;
              }
              return false;
            }).length;
            selectedDashboardName = {
              ...selectedDashboardName,
              [apps[key].dashboardSpaceId]: {
                name: this.props.dashboardList[apps[key].dashboardSpaceId].name,
                counter,
                externalDashboard: this.props.dashboardList[apps[key].dashboardSpaceId]
                  .externalDashboard,
                usersSharedWith: this.props.dashboardList[apps[key].dashboardSpaceId]
                  .usersSharedWith,
              },
            };
          }
          obj[key] = apps[key];
          return obj;
        }, {});

      this.setState({
        apps: filteredApps,
        filteredApps,
        step: 'confirm',
        selectedDashboardName,
      });
      return;
    }
    this.setSubmitting(true);
    this.props.setEditAccountProccessing(true);
    const ids = this.state.selected;
    const sharedByAccepted = [];
    const sharedByPending = [];
    const restAccounts = [];
    const allAccounts = this.state.apps;
    try {
      ids.map((async id => {
        const { externalAccount, shared, accepted } = allAccounts[id];
        if (externalAccount && shared) {
          const params = {
            data: allAccounts[id],
            unshareWithUserIds: [],
          };
          const res = await this.props.removeSharedByAccounts(params);
          sharedByAccepted.push(allAccounts[id]);
        }
        else if (!accepted) {
          const params = {
            sharedId: allAccounts[id].invitationId,
            credentialName: allAccounts[id].name,
            isAccepted: false,
            id: allAccounts[id].id,
          };
          const res1 = await this.props.sharedInvitation(params);
          sharedByPending.push(allAccounts[id]);
        }
        else {
          restAccounts.push(allAccounts[id].id);
        }
      }));

      const accoutsIds = new List(restAccounts);
      const res1 = await this.props.deleteCredentials({ ids: accoutsIds });
      this.setSubmitting(false);
      this.props.hide();
      setTimeout(() => {
      }, 2000);
    }
    catch (error) {
      setTimeout(() => {
        this.setSubmitting(false);
        this.props.setEditAccountProccessing(false);
      }, 2000);
    }
  }

  loadComponentData() {
    const Dashboarddropdown = [];
    const selectedCounter = 0;
    // Dashboarddropdown.push({
    //   name: this.props.userName,
    //   value: null,
    //   counter: this.props.totalCounter,
    //   externalDashboard: false,
    //   usersSharedWith: [],
    // })
    Object.values(this.props.dashboardList).map(dashboard => {
      // if (dashboard.id === this.props.cdid) {
      //   selectedCounter = Object.values(this.props.credentials).filter(cred => {
      //     if (
      //       'dashboardSpaceId' in cred &&
      //       cred.dashboardSpaceId === dashboard.id
      //     ) {
      //       return true;
      //     }
      //     return false;
      //   }).length;
      // }
      // if (
      //   dashboard.id !== this.props.currentDashboard &&
      //   dashboard.id !== this.props.secondDashboard
      // ) {
      const counter = Object.values(this.props.credentials).filter(cred => {
        if (
          'dashboardSpaceId' in cred &&
          cred.dashboardSpaceId === dashboard.id
        ) {
          return true;
        }
        return false;
      }).length;
      return Dashboarddropdown.push({
        name: dashboard.name,
        value: dashboard.id,
        counter,
        externalDashboard: dashboard.externalDashboard,
        usersSharedWith: dashboard.usersSharedWith,
      });
      // }
      return false;
    });
    this.setState({
      dashboardDropdown: Dashboarddropdown,
      selectedDashboard: this.props.userName,
      selectedDashboardId: null,
      selectedDashboardCounter: this.props.totalCounter,
      currentDashboard: null,
      dashboardName: this.props.userName,
      cdid: this.props.cdid,
      secondDashboard: null,
      dashboardCounter: this.props.totalCounter,
    }, () => this.loadAccounts());
  }

  loadAccounts() {
    const tmpApps = Object.keys(this.props.credentials).reduce((obj, key) => {
      const { groupId } = this.props.credentials[key];
      let groupName;
      if (this.props.groups[groupId]) {
        groupName = this.props.groups[groupId].label;
      }
      const isUnsorted = groupName === LABEL_UNSORTED_GROUP;
      groupName = isUnsorted ? '' : (groupName || '');
      obj[key] = { ...this.props.credentials[key] };
      obj[key].groupId = groupName;
      obj[key].showPassword = false;
      return obj;
    }, {});

    let apps = {};
    Object.values(tmpApps).map(obj => {
      let validate = true;
      if (obj.invitationId !== '') {
        validate = false;
      }
      else {
        apps = {
          ...apps,
          [obj.id]: obj,
        };
      }
      return validate;
    });

    this.setState({
      apps,
      filteredApps: apps,
    });
  }

  // TODO very slow, needs perf improvements
  search(query) {
    if (!query) query = '';
    query = query.toUpperCase();
    let apps = [];
    Object.entries(this.props.credentials).forEach(([, app]) => {
      const displayLogin = app.email || app.username || app.phone;
      let match = !query;

      if (!match) {
        const matchesName = app.name.toUpperCase().startsWith(query);
        match = matchesName;
      }

      if (!match) {
        const matchesDisplayLogin = displayLogin.toUpperCase().startsWith(query);
        match = matchesDisplayLogin;
      }

      if (match) {
        let isChecked = false;
        if (this.state.selected.length) {
          this.state.selected.forEach(item => {
            if (app.id === item.id) {
              isChecked = true;
            }
          });
        }
        apps = [
          ...apps,
          Object.assign({ checked: isChecked, displayLogin }, app),
        ];
      }
    });

    apps = apps.sort((a, b) => {
      const aName = a.name ? a.name.toUpperCase() : '';
      const bName = b.name ? b.name.toUpperCase() : '';
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });

    this.setState({ apps });
  }

  setLoader(isLoading) {
    this.props.AsyncSetState(() => this.setState({ isLoading }));
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  togglePassword(id) {
    this.setState({
      filteredApps: {
        ...this.state.filteredApps,
        [id]: {
          ...this.state.filteredApps[id],
          showPassword: !this.state.filteredApps[id].showPassword,
        },
      },
      apps: {
        ...this.state.apps,
        [id]: {
          ...this.state.apps[id],
          showPassword: !this.state.apps[id].showPassword,
        },
      },
    });
  }

  toggleSelected(id) {
    let { selected } = this.state;
    selected = arrayItemSliceOrAppend(selected, id);
    const isSubmitDisabled = selected.size === 0;
    this.setState({ isSubmitDisabled, selected });
  }

  onClickBack() {
    if (this.state.step == 'confirm') {
      this.setState({
        step: 'select',
        isSubmitDisabled: true,
        selected: new List(),
      });
      this.loadAccounts();
    }
    else {
      this.props.onClickBackHandler();
    }
  }

  openDropdown() {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  }

  onChangeDashboard(selectedDashboardId, selectedDashboardName, selectedDashboardCounter) {
    const Dashboarddropdown = [];
    if (
      this.props.userName.toLowerCase() !== selectedDashboardName.toLowerCase()
    ) {
      const totalCounter = Object.values(this.props.credentials).filter(cred => {
        if (
          (!('dashboardSpaceId' in cred) ||
          cred.dashboardSpaceId === '' ||
          cred.dashboardSpaceId === null) &&
          cred.invitationId === ''
        ) {
          return true;
        }
        return false;
      }).length;
      Dashboarddropdown.push({
        name: this.props.userName,
        value: null,
        counter: totalCounter,
        externalDashboard: true,
      });
    }
    Object.values(this.props.dashboardList).map(dashboard => {
      if (
        selectedDashboardName.toLowerCase() !== dashboard.name.toLowerCase() &&
        this.state.currentDashboard !== dashboard.id
      ) {
        const counter = Object.values(this.props.credentials).filter(cred => {
          if (
            'dashboardSpaceId' in cred &&
            cred.dashboardSpaceId === dashboard.id
          ) {
            if (
              this.state.currentDashboard != null &&
              this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
              this.props.state.ui.dashboards[this.state.currentDashboard]
                .usersSharedWith.length > 0 &&
              (('usersSharedWith' in cred &&
              cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
              (cred.sharedByUserName != false && cred.sharedByUserName != null))
            ) {
              return false;
            }
            return true;
          }
          return false;
        }).length;
        Dashboarddropdown.push({
          name: dashboard.name,
          value: dashboard.id,
          counter,
          externalDashboard: dashboard.externalDashboard,
          usersSharedWith: dashboard.usersSharedWith,
        });
      }
    });
    this.setState({
      dashboardDropdown: Dashboarddropdown,
      selectedDashboard: selectedDashboardName,
      selectedDashboardId,
      selectedDashboardCounter,
      showDropdown: false,
    });
    let query = this.state.searchQuery;
    let filterApps = {};
    if (query === '') {
      Object.keys(this.state.apps)
        .forEach(item => {
          if ('dashboardSpaceId' in this.state.apps[item] &&
          this.state.apps[item].dashboardSpaceId === selectedDashboardId) {
            if (
              this.state.currentDashboard != null &&
              this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
              this.props.state.ui.dashboards[this.state.currentDashboard]
                .usersSharedWith.length > 0 &&
              (('usersSharedWith' in this.state.apps[item] &&
              this.state.apps[item].usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
              (this.state.apps[item].sharedByUserName != false &&
                this.state.apps[item].sharedByUserName != null))
            ) {
              return false;
            }
            filterApps = {
              ...filterApps,
              [item]: this.state.apps[item],
            };
          }
          return true;
        });
      this.setState({
        filteredApps: filterApps,
      });
    }
    else {
      const { apps } = this.state;
      query = query.toLowerCase();
      const filteredApps = Object.keys(apps)
        .filter(item => {
          if (
            'dashboardSpaceId' in this.state.apps[item] &&
              this.state.apps[item].dashboardSpaceId === selectedDashboardId
          ) {
            if (
              this.state.currentDashboard != null &&
                this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
                this.props.state.ui.dashboards[this.state.currentDashboard]
                  .usersSharedWith.length > 0 &&
                (('usersSharedWith' in this.state.apps[item] &&
                this.state.apps[item].usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
                (this.state.apps[item].sharedByUserName != false &&
                  this.state.apps[item].sharedByUserName != null))
            ) {
              return false;
            }
            return true;
          }
          return false;
        })
        .filter(key => apps[key].name.toLowerCase().startsWith(query))
        .reduce((obj, key) => {
          obj[key] = apps[key];
          return obj;
        }, {});
      this.setState({
        filteredApps,
      });
    }
  }

  render() {
    return (
      <Container
        apps={this.state.filteredApps}
        canSubmit={!this.state.isSubmitDisabled}
        isLoading={this.state.isLoading}
        isSubmitting={this.state.isSubmitting}
        onCancel={this.hidePopup}
        onClickClearSearch={this.clearSearch}
        onChangeSearch={this.handleChange}
        onClickApp={this.toggleSelected}
        onClickPasswordToggle={this.togglePassword}
        onSubmit={this.handleSubmit}
        query={this.state.query}
        selected={this.state.selected}
        step={this.state.step}
        onClickBackHandler={this.onClickBack}
        dashboardName={this.state.dashboardName}
        dashboards={this.state.dashboardDropdown}
        onChangeDashboard={this.onChangeDashboard}
        selectedDashboard={this.state.selectedDashboard}
        selectedApps={this.state.selectedApps}
        selectedDashboardName={this.state.selectedDashboardName}
        userName={this.props.userName}
        selectedDashboardCounter={this.state.selectedDashboardCounter}
        alreadySharedDashboard={this.props.alreadySharedDashboard}
        sharedWithMeDashboard={this.props.sharedWithMeDashboard}
        dashboardCounter={this.state.dashboardCounter}
        openDropdown={this.openDropdown}
        showDropdown={this.state.showDropdown}
        switchDashboards={this.switchDashboards}
        dashboardList={this.props.dashboardList}
        selectedDashboardId={this.state.selectedDashboardId}
        currentDashboard={this.state.currentDashboard}
        t={this.props.t} />
    );
  }
}

const mapStateToProps = state => {
  let dashboardName = '';
  let cdid = null;
  const secondDashboard = null;
  const dashboardCounter = 0;
  let dashboardList = {};
  if (
    state.ui.modalData !== null &&
    'id' in state.ui.modalData &&
    state.ui.modalData.id !== null &&
    'name' in state.ui.dashboards[state.ui.modalData.id]
  ) {
    dashboardName = state.ui.dashboards[state.ui.modalData.id].name;
    cdid = state.ui.modalData.id;
  }
  else if (
    (
      state.ui.currentDashboard === '' ||
      state.ui.currentDashboard === null
    ) &&
    (
      state.ui.modalData === null ||
      state.ui.modalData.id === null
    )
  ) {
    dashboardName = state.user.profile.name;
    cdid = null;
  }
  else if (
    (
      state.ui.currentDashboard !== '' ||
      state.ui.currentDashboard !== null
    ) &&
    (
      state.ui.modalData !== null &&
      'id' in state.ui.modalData &&
      state.ui.modalData.id === null
    )
  ) {
    dashboardName = state.user.profile.name;
    cdid = null;
  }
  else {
    dashboardName = state.ui.dashboards[state.ui.currentDashboard].name;
    cdid = state.ui.currentDashboard;
  }

  // const totalCounter = 0;
  const totalCounter = Object.values(state.credentials.data).filter(cred => {
    if (
      (!('dashboardSpaceId' in cred) ||
      cred.dashboardSpaceId === '' ||
      cred.dashboardSpaceId === null) &&
      cred.invitationId === ''
    ) {
      if (
        cdid != null &&
        state.ui.dashboards[cdid].shared &&
        state.ui.dashboards[cdid]
          .usersSharedWith.length > 0 &&
        (('usersSharedWith' in cred &&
        cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
        (cred.sharedByUserName != false && cred.sharedByUserName != null))
      ) {
        return false;
      }
      return true;
    }
    return false;
  }).length;
  // dashboardCounter = Object.values(state.credentials.data).filter(cred => {
  //   if (
  //     (
  //       !('dashboardSpaceId' in cred) &&
  //       cdid === null
  //     )
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }).length;

  Object.keys(state.ui.dashboards).map(dashboardId => {
    if (
      (!('externalDashboard' in state.ui.dashboards[dashboardId]) || (!state.ui.dashboards[dashboardId].externalDashboard)) && (!('invitationId' in state.ui.dashboards[dashboardId]))
    ) {
      dashboardList = {
        ...dashboardList,
        [dashboardId]: { ...state.ui.dashboards[dashboardId] },
      };
    }
  });

  console.log('snivsnueiv', dashboardList, secondDashboard);
  let creds = {};
  Object.keys(state.credentials.data).map(cred => {
    if (
      !('dashboardSpaceId' in state.credentials.data[cred]) ||
      state.credentials.data[cred].dashboardSpaceId == '' ||
      state.credentials.data[cred].dashboardSpaceId == null ||
      (state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId] &&
        !('externalDashboard' in state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId])) ||
      (state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId] &&
        !state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId].externalDashboard)
    ) {
      creds = {
        ...creds,
        [cred]: {
          ...state.credentials.data[cred],
        },
      };
    }
  });
  return {
    state,
    credentials: creds,
    groups: state.groups.data,
    data: state.ui.modalData,
    editAccountProccessing: state.ui.editAccountProccessing,
    currentDashboard: cdid,
    userName: state.user.profile.name,
    dashboardList,
    dashboardName,
    cdid,
    secondDashboard,
    totalCounter,
    dashboardCounter,
    token: state.session.accessToken,
    dashboards: state.ui.dashboards,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  removeSharedByAccounts: params => dispatch(removeSharedByAccounts(params)),
  sharedInvitation: params => dispatch(sharedInvitation(params)),
  hide: () => {
    // Remove the query string, otherwise the modal would keep opening.
    history.replace({ search: '' });
    dispatch(hideModal());
  },
  readGroup: id => dispatch(readGroup(id)),
  onClickBackHandler: () => dispatch(showModal(MODAL_ADD_ACCOUNT_NAVLIST)),
  showModal: (template, data) => dispatch(showModal(template, data)),
  showToaster: args => dispatch(RXToasterShow(args)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAsyncState(withOverlayAction(ModalAccountRemoveComponent))));
