/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH2 } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18nRenderLines, noop } from '@nettoken/helpers';
import ActionBar from 'Modal/ActionBar';
import Input from 'Input';
import AppsPreview from 'AppsPreview';
import Counter from 'Counter';
import Tabs from 'Modal/tabs';
import Styles from 'Modal/style.css';
import { Button } from '@nettoken/components';
import downloadfile from 'assets/file/Nettoken template.csv';
import SupportedImportPlatformsList from './platforms';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import ClearIcon from '../../../ActionIcons/ClearIcon';

class ModalTemplateTransferContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisable: false,
    };
  }

  renderUpload() {
    const { t } = this.props;
    return (
      <div className={Styles.uploadWrapper}>
        <p className={Styles.emailScanDisclaimer} >
        If you keep your passwords in notes or spreadsheets:
        </p>

        <ol className={classNames({
          [Styles.emailScanDisclaimer]: true,
          [Styles.numList]: true,
        })}>
          <li>Download the template below</li>
          <li>Fill in your usernames and passwords</li>
          <li>Save the file </li>
          <li>Upload the file here</li>
          <li>Preview and add the accounts</li>
          <li>Delete the file!</li>
        </ol>

        <p className={Styles.emailScanDisclaimer}>
          <a
            className={Styles.fromFilelinkImage}
            href='https://www.youtube.com/watch?v=l4HLU47ygXg'
            target ="_blank"
            rel="noopener noreferrer" >
            <svg width="30px" height="30px" viewBox="0 0 20 14" version="1.1"
            className={Styles.icon}>
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -7442.000000)" fill="#000000">
                      <g id="icons" transform="translate(56.000000, 160.000000)">
                          <path d="M251.988432,7291.58588 L251.988432,7285.97425 C253.980638,7286.91168 255.523602,7287.8172 257.348463,7288.79353 C255.843351,7289.62824 253.980638,7290.56468 251.988432,7291.58588 M263.090998,7283.18289 C262.747343,7282.73013 262.161634,7282.37809 261.538073,7282.26141 C259.705243,7281.91336 248.270974,7281.91237 246.439141,7282.26141 C245.939097,7282.35515 245.493839,7282.58153 245.111335,7282.93357 C243.49964,7284.42947 244.004664,7292.45151 244.393145,7293.75096 C244.556505,7294.31342 244.767679,7294.71931 245.033639,7294.98558 C245.376298,7295.33761 245.845463,7295.57995 246.384355,7295.68865 C247.893451,7296.0008 255.668037,7296.17532 261.506198,7295.73552 C262.044094,7295.64178 262.520231,7295.39147 262.895762,7295.02447 C264.385932,7293.53455 264.28433,7285.06174 263.090998,7283.18289" id="youtube-[#168]"></path>
                      </g>
                  </g>
              </g>
          </svg>
          </a>
          Steps on <a className={Styles.anchorfromFileLabel} href='https://www.youtube.com/watch?v=l4HLU47ygXg' target="_blank" >how to add accounts from a template.</a>
        </p>

        <a className={classNames(Styles.downloadBtn, Styles.templateTranferMarginBottom)}>
        <Button
          className={`${Styles.radioButton} ${Styles.butnTextLower}`}
          icon={true}
          onClick={this.props.onDownload}
          value={t('Download template')}
        />
        </a>
        <Input
          className={classNames(Styles.fromFileInput, Styles.marginHeader)}
          label="Upload file"
          name="file"
          onChange={this.props.onChange}
          placeholder={t('views.import.inputFile')}
          t={t}
          type="file"
          value={this.props.filename}/>
          {this.props.error && <p className={Styles.fromFileError}>
            {this.props.error}
          </p>}
      </div>
    );
  }

  renderPreview() {
    const { t } = this.props;
    return (
      this.props.hasNewAccounts ?
        <div className={Styles.stickyfixedForm2} style={{ backgroundColor: '#f6f6f6' }}>
          <div className={`${Styles.searchWrapper} ${Styles.stickySearchHeader2} ${Styles.addAccountSearchBar}`}>
            <input
              aria-label={t('placeholder.search')}
              className={classNames(Styles.search, Styles._preview, Styles.search_select)}
              onChange={this.props.onChangeSearch}
              placeholder={`${t('placeholder.search')}...`}
              role="search"
              type="text"
              id={SEARCH2}
              value={this.props.query} />
            {this.props.query.length > 0 ?
              <ClearIcon onClickClearSearch={this.props.onClickClearSearch} /> : null}
          </div>
          <div className={`${Styles.actions2} ${Styles.stickyActionBar44}`}></div>
          <div className={`${Styles.addAccountList}`}>
            <AppsPreview
              apps={this.props.apps}
              isEmailEditable={true}
              onChange={this.props.onFieldChange}
              onClickPasswordToggle={this.props.onClickPasswordToggle}
              onSelectApp={this.props.onSelectApp}
              passwordList={this.props.passwordList}
              selectedApps={this.props.selectedApps}
              unsortedId={this.props.unsortedId}
              showUsername={true}
            />
          </div>
        </div> : <p className={Styles.emailScanDisclaimer} style={{
          marginTop: '15px',
        }}>
        {i18nRenderLines(t('modal.importPreview.noNewAccounts'))}
      </p>
    );
  }

  render() {
    const { hasNewAccounts, t } = this.props;
    const isUpload = this.props.step === 'upload';
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountAdd.title')}
          className={classNames({
            [Styles.title]: true,
            [Styles.stickyHeaderList]: true,
          })}>
          <LeftArrowIcon onClickBackHandler={this.props.onClickBackHandler} />
          { isUpload ? 'Transfer to template' : 'Add accounts' }
          <CancelIcon onCancel={this.props.onCancel} />
        </h1>

        <form
          action="/"
          className={classNames({
            [Styles.formEmailScan]: !hasNewAccounts,
            [Styles.formEmailScanPreview]: hasNewAccounts,
            [Styles._fixedActions]: !isUpload,
            [Styles.stickyMinHeightBackground]: !isUpload,
            [Styles.stickyPaddingTop]: !isUpload,
          })}
          style={{
            height: this.props.hasNewAccounts ? '80vh' : 'auto',
          }}
          method="post"
          onSubmit={this.props.onSubmit}>

          {
            isUpload ?
              this.renderUpload() :
              this.renderPreview()
          }

          <ActionBar
            disableSubmit={isUpload ? !this.props.filename : (
              this.props.selectedApps.size == 0 && this.props.hasNewAccounts
            ) }
            isFromFile={true}
            isFixed={isUpload}
            onCancel={this.props.onCancel}
            selected={this.props.selectedApps.size}
            showSelected={!isUpload}
            submitLabel={t(isUpload ? 'global.preview' : 'global.add')}
            submitting={this.props.loading}
            onClickBackHandler={this.props.onClickBackHandler}
            showDoneBtn={!isUpload && !this.props.hasNewAccounts}
            disableInPreview={this.props.disableInPreview}
            hideBackButton={!isUpload}
            t={t}
            isFixedActionBar={this.props.hasNewAccounts}
            styles={{ width: this.props.hasNewAccounts ? '92vw' : 'auto', maxWidth: '1050px' }}/>
        </form>
      </React.Fragment>
    );
  }
}


ModalTemplateTransferContainer.propTypes = {
  onClickClearSearch: PropTypes.func,
  error: PropTypes.string,
};

export default ModalTemplateTransferContainer;
