/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { BACKUP_VIDEO } from 'constants/videos';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Video from 'Video';
import Styles from 'Modal/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import dogKey from '../../../../assets/images/dog_with_key.svg';

const ModalPromptBackupContainer = ({
  loading,
  onCancel,
  onClickDownload,
  stepNo,
  onClickExplain,
  t,
}) => (
  <React.Fragment>
    <h1 aria-label="Download Backup Key" className={Styles.title5}>
      {stepNo === 2 ? 'PDF downloaded!' : 'Download Backup Key'}
      <CancelIcon onCancel={onCancel} />
    </h1>
    { stepNo != 2 ? (
        <form
          action="/"
          className={classNames(Styles.formEmailScanPreviewBackup, Styles.wideModal)}
          method="post"
          onSubmit={noop}>
          <div className={`${Styles.fixedForm3}`}>
            <p className={Styles.emailScanDisclaimer}
              style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
              {t('views.backup.textPrompt')}
            </p>
            <div className={Styles.video_rapper}>
              <Video videoUrl={BACKUP_VIDEO} />
            </div>

            <div className={`${Styles.actions} ${Styles.actionsFixed2} ${Styles.right}`} style={{ width: '780px', bottom: '0vh' }}>
              <Button
                className={Styles.button}
                onClick={onCancel}
                value={t('views.backup.later')} />
                <div style={{ marginLeft: '420px', cursor: 'pointer' }}>
              {
                loading ?
                  <span className={Styles.loader} /> :
                  <Button
                    className={Styles.backupButton}
                    icon={true}
                    loading={loading}
                    onClick={onClickDownload}
                    value={t('views.backup.button')}
                  />
              }
            </div>
            </div>
          </div>
        </form>
    ) : (
      <div>
      <div className={`${Styles.smallModal} ${Styles.smallHeightBackground}`}>
        <div className={Styles.form}>
            <div
              className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}
            >
              <div className={Styles.paddingFixer}>
                <center>
                  <img src={dogKey} />
                </center>
                <ul>
                  <li className={`${Styles.exportSectionItem}`}>
                    <p className={Styles.radioTextInExport}>
                      <p><b>Keep your backup key PDF in a safe place</b> that
                      you can reach in the event that you: </p>
                      <br />
                      <p>
                        1. lose your phone <br/>
                        2. delete the Nettoken phone app<br/>
                        3. transfer your Nettoken to a new phone<br/><br/>
                        Please note that when you download a new
                        backup key it will make your old copy invalid.
                      </p>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </div>
      <div
      className={`${Styles.actions} ${Styles._squeezedAltered} ${Styles.actionsFixed2} ${Styles.fixedButtons}`}
      >
        <Button
          className={`${Styles.button}`}
          onClick={onClickExplain}
          value='Backup key explained'
          type="submit"
        />
      </div>
    </div>
    )}
  </React.Fragment>
);

ModalPromptBackupContainer.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickDownload: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalPromptBackupContainer;
