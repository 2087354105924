/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { BACKUP_VIDEO } from 'constants/videos';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Video from 'Video';
import Styles from 'Modal/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';

const ModalPromptBackupContainer = ({
  loading,
  onCancel,
  onClickDownload,
  t,
  onBack,
}) => (
  <React.Fragment>
    <h1
      aria-label={t('views.backup.sidebarButton')}
      className={Styles.title1}
    >
      <LeftArrowIcon onClickBackHandler={onBack} />
      Backup key explained
      <CancelIcon onCancel={onCancel} />
    </h1>

    <div
      className={classNames(Styles.formEmailScanPreviewBackup, Styles._boxPadding)}>
      <p className={Styles.emailScanDisclaimer}
        style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
        Watch this short video to learn more about the Nettoken backup key.
      </p>
      <div className={Styles.video_rapper}>
        <Video videoUrl={BACKUP_VIDEO} />
      </div>

        <div
          className={`${Styles.actions}`}
          style={{
            justifyContent: 'center',
            width: '740px',
          }}
        >
        <div>
        {
          loading ?
            <span className={Styles.loader} /> :
            <Button
              className={classNames({
                [Styles.button]: true,
                [Styles.notDisabled]: true,
                [Styles.cancelMarginLeft]: true,
              })}
              type="submit"
              onClick={onCancel}
              value="GOT IT!"
            />
        }
      </div>
      </div>
    </div>
  </React.Fragment>
);

ModalPromptBackupContainer.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickDownload: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalPromptBackupContainer;
